<template>
  <div class="console_r">
    <div class="console_r_card">
      <div class="title">
        <b>{{currMenuItem.name}}</b>
        <!-- <span style="cursor: pointer" @click="instrDialogVisible = true">案例说明</span> -->
      </div>
      <div class="con">
        <ul>
          <li v-for="(item, index) in currMenuItem.fun" :key="index">{{item}}<i></i></li>
          <div style="clear: both;"></div>
        </ul>
        <div class="des">
          <p v-for="(item, index) in currMenuItem.des" :key="index">{{item}}</p>
        </div>
        <el-upload
          class="upload-warp"
          ref="upload"
          :action="`${baseUrl}api/upload/ali-oss`"
          :headers="uploadHeaders"
          :multiple="false"
          :limit="1"
          :file-list="fileList"
          :auto-upload="false"
          accept="image/png,image/jpeg,image/jpg"
          :on-exceed="handleExceed"
          :on-change="handleChange"
          :on-success="handleSuccess"
          :on-progress="handleProgress"
          :on-error="handleError"
          :show-file-list="false">
          <div class="upload" slot="trigger">
            <span v-if="fileList.length<=0" class="c9">请选择上传文件</span>
            <span v-if="fileList.length>0">{{fileList[0].name}}</span>
            <div class="btn">
              <sh-button size="small"></sh-button>
            </div>
          </div>
        </el-upload>
        <div>
          <div class="ipt_notes" style="text-align: left; margin-top: 10px">
            <sh-button class="button-a" @click="submitUpload">上传</sh-button><span v-if="isShowProgress">{{`上传进度：${uploadProgress}%`}}</span> {{uploadTip}}
          </div>
          <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，且不超过15M</div>
        </div>
      </div>
    </div>
    <div class="console_r_card">
      <div class="title">
        <b>历史记录<i @click="refresh" class="el-icon-refresh" title="刷新"></i></b>
        <span>当前记录：{{count}}</span>
      </div>
      <div class="con">
        <div class="con_top" style="padding: 30px 0;">
          <el-date-picker
            v-model="dateTimeFilter"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :editable="false"
            size="large"
            @change="dateChange"
            align="right">
          </el-date-picker>
          <sh-button @click="search" round>查询</sh-button>
          <sh-button @click="handleExport" round>导出</sh-button>
        </div>
        <el-table :data="list" style="width: 100%" border>
          <el-table-column label="缩略图" width="120">
            <template slot-scope="scope">
              <div class="itme-img" @click="viewDetail(scope.row)" :style="`background-image: url(${scope.row.source_image})`"></div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="file_ori_name" label="原始文件名"></el-table-column> -->
          <!-- <el-table-column prop="result" label="模型识别内容"></el-table-column>
          <el-table-column prop="egg_type_name" label="鸡蛋名称"></el-table-column>
          <el-table-column prop="egg_type_desc" label="鸡蛋描述"></el-table-column> -->
          <el-table-column prop="shape_index" label="蛋形指数"></el-table-column>
          <el-table-column prop="statusTxt" label="状态"></el-table-column>
          <el-table-column prop="date" label="日期"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="viewDetail(scope.row)" round>查看大图</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center">
        <el-pagination
        style="padding: 20px 0 40px"
        background
        layout="prev, pager, next"
        @current-change="currPageChange"
        :page-size="queryParams.limit"
        :current-page.sync="currPage"
        :total="count">
        </el-pagination>
      </div>
    </div>
    <el-dialog 
    class="dialog"
    title="案例说明" 
    :width="`${currMenuItem.instr.length * 350}px`"
    :visible.sync="instrDialogVisible">
      <div class="detail-item">
        <ul class="instr_con">
          <li v-for="(item, index) in currMenuItem.instr" :key="index" :style="`width: ${100/currMenuItem.instr.length}%`">
            <img alt="" :src="item.img" />
            <p v-html="item.des"></p>
          </li>
        </ul>
        <div style="clear: both"></div>
      </div>
    </el-dialog>
    <el-dialog 
    class="dialog"
    title="大图" 
    @close="bigImageClose"
    @opened="bigImageOpened"
    :width="`${bigImageDialogWidth}px`"
    :visible.sync="detailDialogVisible">
      <div class="detail-item">
        <div class="big-img">
          <canvas ref="canvas" :width="`${bigImageDialogWidth-60}px`" :height="`${canvasHeight}px`"></canvas>
          <!-- <img alt="" :src="currViewDetailData.source_image" />
          <div 
          v-if="(imageFrameInfo.w > 0 && imageFrameInfo.h > 0)" 
          class="img-region" 
          :style="`left: ${imageFrameInfo.x}px; top: ${imageFrameInfo.y}px; width: ${imageFrameInfo.w}px; height: ${imageFrameInfo.h}px; transform: rotate(${imageFrameInfo.angle - 90}deg); transform-origin: 0% 0%;`">
          </div> -->
        </div>
      </div>
      <!-- <div class="detail-item">
        <div v-for="(item, index) in currMenuItem.detailList" :key="index"><span>{{item.title}}：</span>{{currViewDetailData[item.key]}} {{item.unit}}</div>
        <div><span>处理状态：</span>{{currViewDetailData.statusTxt}}</div>
        <div><span>处理时间：</span>{{currViewDetailData.date}}</div>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { Message } from 'element-ui';
import { submitChickenFun, getChickenListFun, getChickenCountFun } from '@/api/user'
import { getToken, getAccountId } from '@/libs/util'
import config from '@/config'
// import ShInput from '@/components/uikit/input'
import ShButton from '@/components/uikit/button'

export default {
  name: 'PoultryIndex',
  components: {
    // ShInput,
    ShButton,
  },
  data() {
    return {
      currType: 'egg',  // this.$route.params.type,
      uploadProgress: 0,
      isShowProgress: false,
      fileList: [],
      currFileUrl: null,
      uploadTip: '',
      baseUrl: config.baseUrl,
      uploadHeaders: {
        'account-id': getAccountId(),
        'Authorization': getToken()
      },
      loadingData: false,
      queryParams: {
        skip: 0,
        limit: 10,
        start_time: '',
        end_time: '',
        detect_type: 2
      },
      list: [],
      count: 0,
      currPage: 1,
      currMenuItem: {},
      postParams: {
        detect_type: 2,
        source_image: null,
      },
      detailDialogVisible: false,
      instrDialogVisible: false,
      currViewDetailData: {},
      dateTimeFilter: '',
      bigImageDialogWidth: 800,
      imageFrameInfo: {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        angle: 0
      },
      canvasHeight: 0,
      imageRatio: 1
    }
  },
  methods: {
    handleExport() {
      // const data = [
      //   ['姓名', '年龄'],
      //   ['Tom', 20],
      //   ['Jim', 30]
      // ]
      // const ws = XLSX.utils.aoa_to_sheet(data)
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      // XLSX.writeFile(wb, 'data.xlsx')
      getChickenListFun(this.currType, {...this.queryParams, limit: 100000}).then(res=>{
        let currTime = moment().valueOf()
        let list = res.data.data
        import('@/libs/Export2Excel').then(excel => {
          const tHeader = ['ID', '图片路径', '蛋形指数', '状态', '日期']
          const filterVal = ['id', 'source_image', 'shape_index', 'status', 'created_at']
          const data = list.map(v => filterVal.map(j => {
            if (j === 'created_at') {
              return moment(v[j]).format("YYYY-MM-DD HH:mm:ss")
            } else if (j === 'status') {
                if(v[j] === 1) {
                  return '等待中'
                } else if(v[j] === 2) {
                  return '处理中'
                } else if(v[j] === 3) {
                  return '处理成功'
                } else {
                  return '未能识别'
                }
            } else {
              return v[j]
            }
          }))
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: `${this.currType}-table-list-${currTime}`
          })
        })
      }).catch(() => {
        Message.error('请求数据错误')
      })
    },
    submitUpload() {
      if(this.fileList.length <= 0 || this.fileList[0].name === '') {
        // this.uploadTip = '请选择图片'
        Message.error('请选择图片')
        return false
      }
      this.isShowProgress = true
      // this.uploadTip = '上传中...'
      Message.info('上传中...')
      this.uploadProgress = 0
      this.$refs.upload.submit()
    },
    handleExceed(files, fileList) {
      this.$set(fileList[0], 'raw', files[0]);
      this.$set(fileList[0], 'name', files[0].name);
      this.$refs['upload'].clearFiles();//清除文件
      this.$refs['upload'].handleStart(files[0]);//选择文件后的赋值方法
    },
    handleChange (file, fileList) {
      let _this = this
      console.log('filesss', file)
      console.log('fileList2', fileList)
      console.log('url', URL.createObjectURL(file.raw))
      // let reader = new FileReader()
      // reader.readAsDataURL(file.raw)
      // reader.onload = () => {
      //   let img = new Image()
      //   img.src = reader.result
      //   img.onload = () => {
      //     console.log('img', img.width)
      //     console.log('img', img.height)
      //   }
      // }

      if(file.size > 15 * 1024 * 1024) {
        // this.uploadTip = '所选图片过大'
        Message.error('所选图片过大')
        return false
      }
      if(file.status === 'success' || file.status === 'fail') {
        this.$refs['upload'].clearFiles()
      } else {
        // this.uploadTip = ''
        this.fileList = []
        this.fileList.push(file)
        this.currFileUrl = URL.createObjectURL(file.raw)
      }
      console.log('this.filelist', this.fileList)
    },
    setTimeoutTip() {
      setTimeout(() => {
        this.fileList = []
        this.uploadTip = ''
      }, 2000)
    },
    setShowProgress() {
      setTimeout(() => {
        this.isShowProgress = false
      }, 200)
    },
    handleProgress (event, file, fileList) {
      this.uploadProgress = parseFloat(event.percent).toFixed(2)
      if(this.uploadProgress >= 100) {
        Message.info('上传成功，正在进行识别...')
      }
    },
    handleSuccess (res, file, fileList) {
      if(res.result_code === 0) {
        // this.uploadTip = '上传成功，正在进行识别...'
        // Message.info('上传成功，正在进行识别...')
        // this.$refs['upload'].clearFiles()
        this.postParams.source_image = res.data.key
        submitChickenFun(this.currType, this.postParams).then(res=>{
          Message.info('识别成功，请稍后点击刷新按钮查看结果...')
          this.fileList = []
          this.postParams.source_image = null
          // for(let k in this.postParams) {
          //   this.postParams[k] = null
          // }
        }).catch(() => {
          Message.error('识别失败，请重试...')
          this.fileList = []
          this.postParams.source_image = null
          // for(let k in this.postParams) {
          //   this.postParams[k] = null
          // }
        })
      } else {
        // this.uploadTip = res.err_detail
        Message.error(res.err_detail)
        // this.setTimeoutTip()
        this.fileList = []
      }
      this.setShowProgress()
    },
    handleError (err, file, fileList) {
      // this.uploadTip = '上传失败'
      Message.error('上传失败')
      this.$refs['upload'].clearFiles()
      this.fileList = []
      this.setShowProgress()
    },
    chickenList (params) {
      this.loadingData = true
      getChickenListFun(this.currType, params).then(res=>{
        this.list = res.data.data
        this.list.forEach((item) => {
          item.date = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
          if(item.status === 1) {
            item.statusTxt = '等待中'
          } else if(item.status === 2) {
            item.statusTxt = '处理中'
          } else if(item.status === 3) {
            item.statusTxt = '处理成功'
          } else {
            item.statusTxt = '未能识别'
          }
        })
        this.loadingData = false
      }).catch(() => {
        this.loadingData = false
      })
    },  
    getChickenCount (params) {
      getChickenCountFun(this.currType, params).then(res => {
        this.count = res.data.data.count
      })
    },
    search () {
      this.queryParams.skip = 0
      this.currPage = 1
      this.refresh()
    },
    refresh () {
      this.getChickenCount(this.queryParams)
      this.chickenList(this.queryParams)
    },
    dateChange (e) {
      // console.log(e)
      // console.log(this.dateTimeFilter)
      if(e) {
        this.queryParams.start_time = moment(e[0]).valueOf()
        this.queryParams.end_time = moment(e[1]).valueOf()
      } else {
        this.queryParams.start_time = ''
        this.queryParams.end_time = ''
      }
    },
    currPageChange (currPage) {
      this.queryParams.skip = (currPage - 1) * this.queryParams.limit
      this.chickenList(this.queryParams)
    },
    viewDetail (obj) {
      let _this = this
      _this.detailDialogVisible = true
      _this.currViewDetailData = obj
      let img = new Image()
      img.src = obj.source_image
      img.onload = () => {
        console.log('img', img.width)
        console.log('img', img.height)
        _this.imageRatio = (_this.bigImageDialogWidth-60) / img.width
        _this.canvasHeight = img.height * _this.imageRatio
        // console.log('img', imageRatio)
        // if(!!obj.egg_pos) {
        //   let eggPos = obj.egg_pos
        //   // if(pointArr.length !== 4) return
        //   _this.imageFrameInfo.x = eggPos.points[0][0] * imageRatio
        //   _this.imageFrameInfo.y = eggPos.points[0][1] * imageRatio
        //   _this.imageFrameInfo.w = eggPos.size[1] * imageRatio
        //   _this.imageFrameInfo.h = eggPos.size[0] * imageRatio
        //   _this.imageFrameInfo.angle = obj.egg_pos.angle
        //   console.log(_this.imageFrameInfo)
        // }
      }
    },
    bigImageOpened() {
      let _this = this
      let obj = this.currViewDetailData
      let eggPos = obj.egg_pos.points
      let canvas = this.$refs.canvas
      let ctx = canvas.getContext('2d')
      let img = new Image()
      img.src = obj.source_image
      img.onload = () => {
        console.log(img.width, img.height)
        // let imageRatio = (_this.bigImageDialogWidth-60) / img.width
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width*_this.imageRatio, img.height*_this.imageRatio)
        ctx.beginPath()
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgb(0, 157, 255)';
        eggPos.forEach((p, i) => {
          console.log(p[0])
          ctx[i == 0 ? "moveTo" : "lineTo"](p[0]*_this.imageRatio, p[1]*_this.imageRatio);
        });
        ctx.closePath();
        ctx.stroke();
        ctx.restore();
      }
    },
    bigImageClose () {
      let canvas = this.$refs.canvas
      let ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // Object.keys(this.imageFrameInfo).forEach(k => {
      //   this.imageFrameInfo[k] = 0
      // })
    }
  },
  watch: {
  },
  created(){
    this.currMenuItem = {
            code: 'egg',
            name: '蛋形指数',
            pathName: 'poultryIndex',
            fun: [],
            des: ['基于RGB图像，自动抽取鸡蛋图像中包含的品种特征，在线识别', '无需鸡蛋以特定姿势拍照，可识别任意角度，任意环境背景，识别速度快'],
            showIdInput: false,
            detailList: [
              // {
              //   key: 'result',
              //   title: '鸡蛋的品种',
              //   unit: ''
              // },
              {
                key: 'egg_type_name',
                title: '鸡蛋的品种',
                unit: ''
              },{
                key: 'egg_type_desc',
                title: '鸡蛋的描述',
                unit: ''
              }
            ],
            instr: [
              {
                img: '/images/fowl/case_success.jpeg',
                des: '1. 羽毛分散开，彼此不要覆盖 <br />2. 标尺的数字要连续，要铺平'
              },
              {
                img: '/images/fowl/case_success.jpeg',
                des: '1. 羽毛分散开，彼此不要覆盖 <br />2. 标尺的数字要连续，要铺平'
              },
              {
                img: '/images/fowl/case_success.jpeg',
                des: '1. 羽毛分散开，彼此不要覆盖 <br />2. 标尺的数字要连续，要铺平'
              }
            ]
          },
    this.refresh()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import 'poultry.scss';
.big-img {
  line-height: 0;
  position: relative;
  .img-region {
    width: 0px;
    height: 0px;
    border: 1px solid rgb(0, 157, 255);
    background-color: rgba(0, 157, 255, 0.1);
    // pointer-events: none;
    position: absolute;
    left: 0;
    top: 0
  }
}
</style>
